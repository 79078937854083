import React, { useEffect } from "react"
import NoIndex from "../components/no-index"
import { loginUser, getUserJSONData } from "../util/user-auth"
import { siteMetadata } from "../../gatsby-config"

const qs = require("query-string")
var CryptoJS = require("crypto-js")

const Login = ({ location }) => {
  const parsed = qs.parse(location.search)

  function sendMessage() {
    if (typeof window !== `undefined` && !!parsed.ret) {
      const decoded = Buffer.from(parsed.ret, "base64").toString()
      var bytes = CryptoJS.AES.decrypt(
        decoded,
        process.env.GATSBY_LOGIN_PASSPHRASE
      )
      var decrypted = bytes.toString(CryptoJS.enc.Utf8)

      const { meekToken } = JSON.parse(decrypted)
      const loginToken = window.localStorage.getItem("token_pass")
      if (meekToken === loginToken) {
        const { id, displayName, login_type, hostURL } = JSON.parse(decrypted)
        loginUser(id, displayName, login_type)
        window.localStorage.setItem("token_pass", null)
        window.localStorage.setItem("loginCallback", getUserJSONData())

        window.close()
        window.open(hostURL, "_self")
      } else {
        const { siteUrl } = siteMetadata
        window.close()
        window.open(siteUrl, "_self")
      }
    }
  }

  useEffect(() => {
    sendMessage()
  })

  return <div>
    <NoIndex/>
    {location.search !== "" ? `Returning to Meek Code` : null}</div>
}

export default Login
